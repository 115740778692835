import React from "react";
import { Collapse, Timeline } from "antd";
import expand from "../../assets/images/downIcon.svg";

const TimeLineView = (props) => {
    const { Panel } = Collapse;
    const { cardData } = props;

    const renderPanels = () => {
        return cardData.flatMap((card) => {
            return Object.keys(card).flatMap((dateKey) => {
                return card[dateKey].map((record, index) => {
                    const headings = Object.entries(record.recordRow1Data[0]).map(([key, value]) => ({
                        title: `${value}`,
                        recordId: record.recordId,
                        title_field_color: record.title_field_color,
                        isHeading: true // Indicate this is a heading
                    }));

                    const titles = Object.entries(record.recordTitlesData).flatMap(([outerKey, innerObj]) =>
                        Object.entries(innerObj).map(([key, titleName]) => ({
                            title: `${key}: ${titleName || ''}`, // Include key and value
                            recordId: record.recordId,
                            title_field_color: record.title_field_color,
                            isHeading: false // Indicate this is not a heading
                        }))
                    );

                    // Combine the heading titles and the date for the panel header
                    const panelHeader = (
                        <div>
                            <div style={{ fontFamily:"Inter",fontWeight:400,fontSize:"13px",color:"#192228" }}>{headings.map(heading => heading.title).join(' ')}</div>
                            <div style={{ fontFamily:"Inter",fontWeight:300,fontSize:"10px",color:"#192228" }}>{dateKey}</div>
                        </div>
                    );

                    return (
                        <Panel
                            className="site-collapse-custom-panel"
                            style={{ background: "#fff",marginBottom:"1.3%",borderRadius:"5px" }}
                            header={panelHeader}
                            key={`${dateKey}-${index}`}
                        >
                            <Timeline style={{  padding: "1% 1% 0 1%" }}>
                                {titles.map((title, idx) => (
                                    <Timeline.Item
                                        color="#D1D1D1"
                                        key={`${title.recordId}-${idx}`}
                                        style={{ color: title.title_field_color, paddingBottom: "7px",fontFamily:"Inter",fontWeight:500,fontSize:"13px" }}
                                    >
                                        {title.title}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    );
                });
            });
        });
    };

    return (
        <div style={{paddingTop:'1.3%'}}>
            <Collapse
                style={{ border: "none",background:"#f5f5f6" }}
                className="specific-collapse"
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                    <img
                        src={expand}
                        alt=""
                        style={{ transform: isActive ? 'rotate(270deg)' : 'rotate(90deg)', transition: 'transform 0.3s ease' }}
                    />
                )}
                defaultActiveKey={['1']}
            >
                {renderPanels()}
            </Collapse>
        </div>
    );
};

export default TimeLineView;
