import React, { useState, useEffect } from "react";
import LogDefault from "../../assets/images/defaultLog.svg";
import LogHover from "../../assets/images/hoverLog.svg";
import expand from "../../assets/images/downIcon.svg";
import hide from "../../assets/images/hideAccord.svg";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import backIcon from "../../assets/images/backLeft.svg";
import dayjs from "dayjs";
import { useGlobalContext } from "../../lib/storage";
import { DatePicker } from "../../lib/date";
import { FieldReference } from "../../lib/fieldReference";
import { getsearchFieldData, upsertTabData, getTabData, getWindowInitializationData } from "../../services/generic";
import { getTabRecords, formatDisplayField } from "../window/windowUtilities";
import FormField from "../window/FormField";
import Close from "../../assets/images/close-x.svg";
import { Tooltip, Select, Drawer, Row, Col, Card, Form, Input, Collapse, Button, Typography, message, Spin } from "antd";
import "./style.css"
import ThemeJson from "../../constants/UIServer.json"

const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;
const { TextArea, Password } = Input;
const LogWindow = ({ tab, recordId, isHeader, windowDefinition, headerTabId, headerTab, headerRecordData }) => {
  const Themes = ThemeJson;
  // const headerTabForm = Object.assign({}, headerTab);
  const { globalStore } = useGlobalContext();
  const userPreferences = globalStore.userPreferences;
  const innerWidth = window.innerWidth;
  const [headerRecord, setHeaderRecord] = useState()
  const [jsonParam, setJsonParam] = useState()
  const [visible, setVisible] = useState(false);
  const [logForm] = Form.useForm();
  const [headerReferenceList, setHeaderReferenceList] = useState([]);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false)
  const [tabRecordsData, setTabRecordsData] = useState([])
  const [fields, setFields] = useState([])
  const labelValueDiv = {
    border: "0.5px solid transparent",
    // borderRadius: "3px",
    width: "100%",
    // height: "32px",
    padding: "0px 11px 0px",
    marginTop: "-1px",
  };
  const handleIconHover = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };



  useEffect(async () => {
    if (visible) {
      setLoading(true);
      const localWindowDefinition = { ...windowDefinition };
      const localIndex = localWindowDefinition.tabs.findIndex((tab) => tab.ad_tab_id === headerTabId);
      const parentTab = localWindowDefinition.tabs[localIndex];
      const parentTabId = parentTab.ad_tab_id;
      tab[0].fields.sort((a, b) => {
        const x = a.seqno !== null ? parseInt(a.seqno) : a.seqno;
        const y = b.seqno !== null ? parseInt(b.seqno) : b.seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });

      let headerRecordDataLocal;
      let sessionValues = {};

      parentTab.fields.map((field) => {
        if (field.issession === "Y") {
          if (parentTab.tablevel === "0") {
            sessionValues[field.column_name] = headerRecordData[field.ad_field_id];
          } else {
            sessionValues[field.column_name] = headerRecordData[field.ad_field_id];
          }
        }
        return null;
      });
      // if (parentTab.tablevel !== "0") {
      //   localWindowDefinition.tabs[masterParentTabDataIndex].fields.map((field) => {
      //     if (field.issession === "Y") {
      //       sessionValues[field.column_name] = headerRecordData[field.ad_field_id];
      //     }
      //     return null;
      //   });
      // }

      const stringifiedSession = JSON.stringify(sessionValues);
      const updatedSession = stringifiedSession.replace(/\\"/g, '\\"');
      const stringRequest = JSON.stringify(updatedSession);
      setJsonParam(stringRequest);
      headerRecordDataLocal = await getWindowInitializationData(windowDefinition.ad_window_id, tab[0].ad_tab_id, parentTabId, stringRequest);
      setHeaderRecord(headerRecordDataLocal)
      const formFieldsData = logForm.getFieldsValue(true);


      if (Object.keys(formFieldsData).length > 0) {
        if (Object.entries(headerRecordDataLocal).length === 0 && headerRecordDataLocal.constructor === Object) {
          const fieldsData = { ...formFieldsData };
          updateFields(null, fieldsData);
        } else {
          updateFields(null, headerRecordDataLocal);
        }
      }
    }
    setLoading(false);

  }, [visible]); // Add form as a dependency

  const headerTabForm = Object.assign({}, tab[0]);



  const [newHeaders, setNewHeaders] = useState(headerTabForm);

  const updateFields = (_, allValues) => {
    headerTabForm.fields.map((field, i) => {
      if (field.readonlylogic) {
        let string = field.readonlylogic;
        const keys = string.split("@");
        const actualKeys = keys.filter((s) => s.length === 32);
        actualKeys.map((k) => {
          let actualDataValue = allValues[k];
          if (typeof actualDataValue === "string" && isNaN(actualDataValue)) {
            actualDataValue = `'${actualDataValue}'`;
          }
          if (typeof actualDataValue === "boolean") {
            if (actualDataValue) {
              actualDataValue = `'Y'`;
            } else {
              actualDataValue = `'N'`;
            }
          }

          const numberIndex = headerTabForm.fields.findIndex((f) => f.ad_field_id === k);
          if (numberIndex >= 0) {
            const compareField = headerTabForm.fields[numberIndex];
            if (compareField.nt_base_reference_id === FieldReference.Integer || compareField.nt_base_reference_id === FieldReference.Number) {
              if (actualDataValue === "" || actualDataValue === null || actualDataValue === undefined) {
                actualDataValue = 0;
              }
            }
          }

          const actualData = actualDataValue;
          const stringToUpdate = "@" + k + "@";
          return (string = string.replaceAll(stringToUpdate, actualData));
        });

        string = string.replaceAll("=", "==");
        string = string.replaceAll("<==", "<=");
        string = string.replaceAll(">==", ">=");
        string = string.replaceAll("&", "&&");
        string = string.replaceAll("|", "||");
        string = string.replaceAll("====", "===");
        string = string.replaceAll("&&&&", "&&");
        string = string.replaceAll("||||", "||");

        let logicState;
        try {
          logicState = eval(string); // eslint-disable-line
        } catch (error) {
          console.error("Invalid Read Only Logic Condition: ", string);
          logicState = false;
        }

        let temp = true;

        if (headerTab.fields[i].isreadonly === "Y" && !headerTabForm.fields[i].isreadonlyFromLogic) {
          logicState = true;
          temp = false;
          headerTabForm.fields[i].isreadonlyFromLogic = false;
        }

        if (typeof logicState === "boolean") {
          if (logicState) {
            headerTabForm.fields[i].isreadonly = "Y";
            if (temp) {
              headerTabForm.fields[i].isreadonlyFromLogic = true;
            }
          } else {
            headerTabForm.fields[i].isreadonly = "N";
          }

          if (headerTab.fields[i].isreadonly === "Y") {
            headerTabForm.fields[i].isreadonly = "Y";
          }

          setNewHeaders({ ...headerTabForm });
        }
      }

      if (field.displaylogic) {
        let string = field.displaylogic;
        const keys = string.split("@");
        const actualKeys = keys.filter((s) => s.length === 32);
        actualKeys.map((k) => {
          let actualDataValue = allValues[k];
          if (typeof actualDataValue === "string" && isNaN(actualDataValue)) {
            actualDataValue = `'${actualDataValue}'`;
          }
          if (typeof actualDataValue === "boolean") {
            if (actualDataValue) {
              actualDataValue = `'Y'`;
            } else {
              actualDataValue = `'N'`;
            }
          }

          const numberIndex = headerTabForm.fields.findIndex((f) => f.ad_field_id === k);
          if (numberIndex >= 0) {
            const compareField = headerTabForm.fields[numberIndex];
            if (compareField.nt_base_reference_id === FieldReference.Integer || compareField.nt_base_reference_id === FieldReference.Number) {
              if (actualDataValue === "" || actualDataValue === null || actualDataValue === undefined) {
                actualDataValue = 0;
              }
            }
          }

          const actualData = actualDataValue;
          const stringToUpdate = "@" + k + "@";
          return (string = string.replaceAll(stringToUpdate, actualData));
        });

        string = string.replaceAll("=", "==");
        string = string.replaceAll("<==", "<=");
        string = string.replaceAll(">==", ">=");
        string = string.replaceAll("&", "&&");
        string = string.replaceAll("|", "||");
        string = string.replaceAll("====", "===");
        string = string.replaceAll("&&&&", "&&");
        string = string.replaceAll("||||", "||");

        let logicState;
        try {
          logicState = eval(string); // eslint-disable-line
        } catch (error) {
          console.error("Invalid Display Logic Condition: ", string);
          logicState = false;
        }

        if (typeof logicState === "boolean") {
          if (logicState) {
            headerTabForm.fields[i].isdisplayed = "Y";
          } else {
            headerTabForm.fields[i].isdisplayed = "N";
          }
          setNewHeaders({ ...headerTabForm });
        }
      }
      return null;
    });
  };

  // const [fields,setFields] = useState([])
  useEffect(() => {
    let arr = [];
    newHeaders.fields.map((field) => {
      const stats = recordId === "NEW_RECORD" && !isHeader ? (field.showinaddline === "N" ? false : true) : true;
      if (
        field.isdisplayed === "Y" &&
        field.fieldgroup_name === undefined &&
        field.isshowninstatusbar !== "Y" &&
        field.nt_base_reference_id !== "28" &&
        field.column_type !== "Button" &&
        stats) {
        arr.push(field)
      }
    })
    setFields(arr)
  }, [newHeaders])

  useEffect(() => {
    setLoading(true)
    const fetchTabRecords = async () => {
      let arr = [];
      tab[0].fields?.forEach((field) => {
        const stats = recordId === "NEW_RECORD" && !isHeader ? (field.showinaddline === "N" ? false : true) : true;
        if (
          field.isdisplayed === "Y" &&
          field.fieldgroup_name === undefined &&
          field.isshowninstatusbar !== "Y" &&
          field.nt_base_reference_id !== "28" &&
          field.column_type !== "Button" &&
          stats) {
          arr.push(field);
        }
      });
      try {
        const getTabRecordsResponse = await getTabRecords({
          windowId: windowDefinition.ad_window_id,
          ad_tab_id: tab[0].ad_tab_id,
          parentTabId: headerTabId,
          parentRecordID: recordId,
          startRow: "0",
          endRow: "100"
        });
        setTabRecordsData([...getTabRecordsResponse])
        const referenceList = [];
        tab[0].fields.forEach((element) => {
          if (element["nt_base_reference_id"] === FieldReference.List) {
            const list = element.Values;
            if (list !== undefined || list !== null) {
              Object.keys(list).forEach((key) => {
                referenceList.push(list[key]);
              });
            }
          }
        })

        setHeaderReferenceList([...referenceList]);
      } catch (error) {
        console.error("Error fetching tab records:", error);
      }
      setLoading(false)
      // setFields(arr);
    };

    if (tab.length > 0) {
      fetchTabRecords();
    }
  }, [tab])

  const focusSearch = (searchText, field) => {
    if (searchText.target.value === "") {
      // const dependent = field.dependent ? fieldData[field.dependent] : null;
      const dependent = field.dependent ? logForm.getFieldValue(field.dependent) : null;
      const fieldsForAutoCompleteData = logForm.getFieldsValue(true);
      const fieldsForAutoComplete = { ...fieldsForAutoCompleteData };
      Object.entries(fieldsForAutoComplete).map(([key, val]) => {
        tab[0].fields.map((dat) => {
          if (dat.ad_field_id === key) {
            let keyValueField = val;

            if (keyValueField === true) {
              keyValueField = "Y";
            }
            if (keyValueField === false) {
              keyValueField = "N";
            }
            // if (typeof keyValueField === "number") {
            //   keyValueField = `${fieldData[field.dependent]}`;
            // }
            if (dayjs.isDayjs(keyValueField)) {
              keyValueField = `${keyValueField.format("YYYY-MM-DD HH:mm:ss")}`;
            }
            if (keyValueField === "") {
              keyValueField = null;
            }
            if (keyValueField === undefined) {
              keyValueField = null;
            }
            fieldsForAutoComplete[dat.column_name] = keyValueField;
            delete fieldsForAutoComplete[key];
          }
          return null;
        });
        return null;
      });

      const stringifiedFields = JSON.stringify(fieldsForAutoComplete);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const allFieldsData = JSON.stringify(updatedStrings);
      getsearchFieldData(windowDefinition.ad_window_id, recordId, tab[0].ad_tab_id, field.ad_field_id, searchText.target.value, dependent, null)
        .then((searchDataResponse) => {
          try {
            const searchResponseData = JSON.parse(searchDataResponse?.data?.data?.searchField);

            if (searchResponseData && searchResponseData.searchData) {
              const searchData = searchResponseData.searchData;
              setOptions(searchData);
            } else {
              // Handle the case when the response is not as expected
              console.error("Invalid or unexpected response data:", searchDataResponse);
            }
          } catch (error) {
            // Handle JSON parsing errors
            console.error("Error parsing JSON:", error);
          }
        })
        .catch((error) => {
          // Handle any other errors that may occur during the request
          console.error("Request error:", error);
        });

    }
  };

  const getRecordValue = (field, index) => {
    let recordValueField = tabRecordsData[index][field.ad_field_id.concat("_iden")] ? tabRecordsData[index][field.ad_field_id.concat("_iden")] : tabRecordsData[index][field.ad_field_id];

    const refIndex = headerReferenceList.findIndex((list) => list.key === recordValueField);
    if (refIndex >= 0) {
      recordValueField = headerReferenceList[refIndex].value;
    }
    return formatDisplayField(recordValueField, field, "header");
  };

  const onFinish = async (values) => {
    setLoading(true)

    let activeRecordId = "NEW_RECORD"
    Object.entries(values).map(([key, value]) => {
      if (value === true) {
        values[key] = "Y";
      }
      if (value === false) {
        values[key] = "N";
      }
      if (typeof value === "string") {
        values[key] = value;
      }
      if (typeof value === "number") {
        values[key] = `${value}`;
      }
      if (dayjs.isDayjs(value)) {
        values[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (value === "") {
        values[key] = null;
      }
      if (value === undefined) {
        values[key] = null;
      }
      return null;
    });

    if (activeRecordId === "NEW_RECORD") {
      Object.entries(values).map(() => {
        Object.entries(headerRecord).map(([headerKey, headerValue]) => {
          if (values[headerKey] === undefined) {
            if (headerKey.search("_iden") === -1) {
              values[headerKey] = headerValue;
            }
          }
          return null;
        });
        return null;
      });
    }

    const stringifiedFields = JSON.stringify(values);
    const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
    const stringRequest = JSON.stringify(updatedStrings);
    const parentTabId = tab[0].parent_tab_id;

    const upsertResponse = await upsertTabData(windowDefinition.ad_window_id, tab[0].ad_tab_id, activeRecordId, stringRequest, parentTabId, recordId);
    if (upsertResponse.data.data.upsertTab.status === "200") {
      message.success(`${upsertResponse.data.data.upsertTab.message}`);
      const getTabRecordsResponse = await getTabRecords({
        windowId: windowDefinition.ad_window_id,
        ad_tab_id: tab[0].ad_tab_id,
        parentTabId: parentTabId,
        parentRecordID: recordId,
        startRow: "0",
        endRow: "100"
      });
      setTabRecordsData([...getTabRecordsResponse]);
    }
    setLoading(false);
  }

  const onLog = () => {
    setVisible(true)
  }

  const onChange = (key) => {
    console.log(key);
  };

  const getStringValue = (value) => {
    const seen = [];
    if (React.isValidElement(value)) {
      return value.props.children || '';
    } else if (typeof value === 'object' && value !== null) {
      try {
        return JSON.stringify(value, (key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (seen.includes(value)) {
              return;
            }
            seen.push(value);
          }
          return value;
        });
      } catch (error) {
        return '[Circular]';
      }
    }
    return value || '';
  };

  const onSave = () => {
    logForm
      .validateFields()
      .then(() => {
        logForm.submit();
      })
      .catch((error) => {
        // setCollapseAllGroups(true);
        console.error(JSON.stringify(error, null, 2));
      });
  }

  return (
    <span>
      <Tooltip placement="top" title="Log" onMouseEnter={() => handleIconHover('attach')} onMouseLeave={handleIconLeave}>
        <img onClick={onLog} style={{ cursor: "pointer" }} src={hoveredIcon === "attach" ? LogHover : LogDefault} alt="invoice" />&nbsp;{" "}
      </Tooltip>
      <Drawer
        headerStyle={{ background: "#f3f4f9",borderBottom:"none" }}
        title={
          <div style={{ display: "flex", alignItems: "center", height: "7vh",paddingTop:"1.5vh" }}>
              <img onClick={()=>{setVisible(false)}} style={{
              display: "inline-block",
              marginTop:"10px",
              position: "absolute",
              // marginLeft:"0.8em",
              cursor:"pointer",
              top: `${innerWidth < 375 ? "4px" : "5px"}`,
            }} src={backIcon} alt="AuditTrail" />
            <p style={{ fontSize: "2vw", marginTop: "3vh", marginLeft:"0.8em", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "left" }}>{tab[0].name}</p>
            <div style={{ marginRight: "0.7em" }}>
              <Button
                onClick={()=>{setVisible(false)}}
                style={{ fontFamily: "Inter", fontWeight: "700", width: "auto", border: "0.001px solid #d9d9e5", color: "#192228", background: "#fff", borderRadius: "4px", marginLeft: "4px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSave}
                style={{ fontFamily: "Inter", fontWeight: "700", width: "auto", border: "0.001px solid #DDDBDA", color: "#fff", background: "#F4AC21", borderRadius: "4px", marginLeft: "4px" }}
              >
                Save
              </Button>
            </div>
          </div>
        }
        closeIcon={null} bodyStyle={{ background: "#f3f4f9", paddingRight: "2%" }} visible={visible} placement="right" width="75%" onClose={() => { setVisible(false) }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
          <Row gutter={8}>
            <Col span={16}>
              <Form name="logTab" form={logForm} preserve={false} layout="vertical" onFinish={onFinish}>
                <Card style={{ background: "white", borderRadius: "8px", paddingTop: "8px",paddingRight:"1em",paddingLeft:"1em" }}>
                  <Row gutter={[24, 12]}>
                    {fields?.map((field, index) => {
                      const renderField = (field) => {
                        const colSpanValue = 12; // Example value; adjust as needed

                        const colSpan = field.nt_base_reference_id === FieldReference.WYSIWYGEditor || innerWidth < 600 ? 24
                          : innerWidth > 600 && innerWidth < 800 ? 12
                            : colSpanValue;

                        const formItem = () => {
                          switch (field.nt_base_reference_id) {
                            case FieldReference.String:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name}`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <Input style={{borderRadius:"5px"}} disabled={field.isreadonly === "Y"} />
                                </Form.Item>
                              );
                            case FieldReference.TableDir:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name}`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <Select
                                    className="formView"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={false}
                                    disabled={field.isreadonly === "Y"}
                                    onFocus={(e) => focusSearch(e, field)}
                                  >
                                    {options.map((option, index) => (
                                      <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                                        {option.Name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              );
                            case "Selector":
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name}`,
                                    },
                                  ]}
                                >
                                  <Select
                                    className="formView"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    optionFilterProp="children"
                                    allowClear
                                    disabled={field.isreadonly === "Y"}
                                  />
                                </Form.Item>
                              );
                            case FieldReference.List:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name}`,
                                    },
                                  ]}
                                >
                                  <Select
                                    className="formView"
                                    disabled={field.isreadonly === "Y"}
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder={`Select ${field.name}`}
                                    optionFilterProp="children"
                                  >
                                    {field.Values.map((option, index) => (
                                      <Option key={`${index}-${option.value}`} value={option.key}>
                                        {option.value}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              );
                            case FieldReference.Integer:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      validator: async (_, value) => {
                                        try {
                                          if (value) {
                                            const intValue = value.toString();
                                            if (!(intValue.indexOf(".") === -1 && intValue.length <= parseInt(field.displaylength))) {
                                              throw new Error("Invalid Format");
                                            }
                                            if (isNaN(value)) {
                                              throw new Error("Not a Number");
                                            }
                                          }
                                        } catch (error) {
                                          return Promise.reject(new Error("Invalid Integer"));
                                        }
                                      },
                                      message: `Please input proper ${field.name} value`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <Input disabled={field.isreadonly === "Y"} />
                                </Form.Item>
                              );
                            case FieldReference.Number:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      validator: async (_, value) => {
                                        if (value) {
                                          try {
                                            const intValue = value.toString();
                                            if (!(intValue.length <= parseInt(field.displaylength))) {
                                              throw new Error("Invalid Format");
                                            }
                                            if (isNaN(value)) {
                                              throw new Error("Not a Number");
                                            }
                                            if (intValue.length < 1) {
                                              throw new Error("Input Value");
                                            }
                                          } catch (error) {
                                            return Promise.reject(new Error("Invalid Integer"));
                                          }
                                        }
                                      },
                                      message: `Please input ${field.name} with proper value`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <Input disabled={field.isreadonly === "Y"} />
                                </Form.Item>
                              );
                            case FieldReference.ID:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name}`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <Input disabled={field.isreadonly === "Y"} />
                                </Form.Item>
                              );
                            case FieldReference.DateTime:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name} with proper value`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <DatePicker className="formView" style={{ width: "100%", borderRadius: "5px", height: "1rem" }} showTime={true} format={userPreferences.dateTimeFormat} />
                                </Form.Item>
                              );
                            case FieldReference.Date:
                              return (
                                <Form.Item
                                  label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                                  name={field.ad_field_id}
                                  rules={[
                                    {
                                      required: field.ismandatory === "Y",
                                      message: `Please input ${field.name} with proper value`,
                                    },
                                  ]}
                                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                                >
                                  <DatePicker className="formView" style={{ width: "100%", borderRadius: "5px" }} format={userPreferences.dateFormat} />
                                </Form.Item>
                              );
                            default:
                              return null;
                          }
                        };

                        return (
                          <Col
                            key={`${index}-${field.ad_field_id}`}
                            span={colSpan}
                          >
                            {formItem()}
                          </Col>
                        );
                      };
                      return <React.Fragment key={index}>{renderField(field)}</React.Fragment>;

                    })}
                  </Row>
                  <br />
                  <Row>

                  </Row>
                </Card>
                {/* </Col>
          </Row> */}
              </Form>
            </Col>
            <Col span={8}>
              <Card style={{ borderRadius: "8px", background: "white" }}>
                <Collapse style={{ border: "none" }} className="specific-collapse" expandIcon={({ isActive }) => (
                  <img
                    src={expand}
                    alt=""
                    style={{ transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
                  />
                )} defaultActiveKey={['1']} onChange={onChange}>
                  {tabRecordsData.map((record, recordIndex) => (
                    <Panel className="site-collapse-custom-panel" style={{ background: "#fff" }} header={tab[0].addlinename ? tab[0].addlinename : "Add New"} key={recordIndex.toString()}>
                      <Row gutter={[12, 6]}>

                        {tab[0].fields.map((field, index) =>
                          field.isdisplayed === "Y" &&
                            field.fieldgroup_name === undefined &&
                            field.isshowninstatusbar !== "Y" &&
                            field.nt_base_reference_id !== "28" &&
                            field.column_type !== "Button" ? (
                            <Col
                              key={`${index}-${field.ad_field_id}`}
                              span={24}
                              // style={{ paddingLeft: 12, paddingRight: 12 }}
                            >
                              <Text style={{ paddingTop: "-2px" }}>
                                <span style={{ visibility: "hidden" }}>*</span> <span style={{ verticalAlign: "text-bottom", paddingLeft: 1, fontFamily: "Inter",fontSize:"12px",opacity: 0.7, color: "#192228", fontWeight: 400 }}>{field.name}</span>
                              </Text>{" "}
                              {/* <br /> */}
                              <div style={labelValueDiv}>
                                {/* {console.log(getStringValue(getRecordValue(field, recordIndex)))} */}
                                <Input style={{ borderRadius: "6px", background: "#F3F4F4", fontFamily: "Inter", fontWeight: 300 }} value={getStringValue(getRecordValue(field, recordIndex))} />

                              </div>
                            </Col>
                          ) : (
                            ""
                          )
                        )}

                      </Row>
                    </Panel>
                  ))}
                </Collapse>
              </Card>
            </Col>
          </Row>
        </Spin>
      </Drawer>
    </span>
  )
}

export default LogWindow;