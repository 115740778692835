import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal, Form, Input, Spin, message, Select } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import ProfilePic from "../../../assets/images/profile.jpg";
import LockIcon from "../../../assets/images/lock.svg";
import Envlope from "../../../assets/images/envlope.svg";
import { getProfile, getUpdateProfilePassword, getRoleData, getBusinessUnitData, upsertProfiledata } from "../../../services/generic";


const Profile = () => {
  const { Option } = Select;
  const [profileData, setProfileData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State for toggling edit mode
  const [passWordChangeForm] = Form.useForm();
  const [editProfileForm] = Form.useForm(); // Form for editing profile
  const [defaultBusinessUnit, setDefaultBusinessUnit] = useState(null);
  const [role, setRole] = useState(null);
  const [defaultRoleName, setDefaultRoleName] = useState(profileData?.default_cs_role_iden);
  const [rolesData, setRolesData] = useState(null);
  const [businessUnits,setBusinessUnits] = useState(null)
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const profileResponse = await getProfile();
      const parsedProfile = JSON.parse(profileResponse);
       console.log(parsedProfile)
      setProfileData(parsedProfile);
      setProfileUrl(parsedProfile.profile_image);
  
      const rolesData = await getRoleData('91A7AF89B6D44A3C85171F9EFE7AA237');
      console.log('Roles Data:', rolesData); // Log rolesData
      const selectedRole = rolesData.find(res => res.recordid === parsedProfile.default_cs_role_id);
      console.log('Selected Role:', selectedRole); // Log the selected role
      setRole(selectedRole);
      setRolesData(rolesData)
  
      const businessUnits = await getBusinessUnitData('91A7AF89B6D44A3C85171F9EFE7AA237');
      console.log('Business Unit Data:', businessUnits); // Log businessUnits
      const selectedBusinessUnit = businessUnits.find(res => res.recordid === parsedProfile.default_cs_bunit_id);
      console.log('Selected Business Unit:', selectedBusinessUnit); // Log the selected business unit
      setDefaultBusinessUnit(selectedBusinessUnit);
      setBusinessUnits(businessUnits)

      // Set initial form values
      editProfileForm.setFieldsValue({
        name: parsedProfile.name,
        role: selectedRole?.name,
        businessUnit: selectedBusinessUnit?.name,
        email: parsedProfile.email,
      });
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const getUpdateModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRoleChange = (value) => {
    // Find the role name based on the selected role ID
    const selectedRole = rolesData.find(role => role.recordid === value);
    if (selectedRole) {
      setDefaultRoleName(selectedRole.name);
      // Update form values for both ID and name (iden)
      editProfileForm.setFieldsValue({
        default_cs_role_id: value,
        default_cs_role_iden: selectedRole.name,
      });
    }
  };

  const updatePassword = async () => {
    try {
      const values = await passWordChangeForm.validateFields();
      setLoading(true);
      const updatePasswordResponse = await getUpdateProfilePassword(values);

      if (updatePasswordResponse.messageCode === "200") {
        message.success(updatePasswordResponse.message);
        passWordChangeForm.resetFields();
        setOpenModal(false);
      } else {
        message.error(updatePasswordResponse.message);
      }
    } catch (error) {
      console.error("Password update failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    editProfileForm.setFieldsValue({
      name: profileData.name,
      role: role?.name,
      businessUnit: defaultBusinessUnit?.name,
      email: profileData.email,
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    editProfileForm.resetFields(); // Reset form values to initial values
  };

  const handleSaveEdit = async () => {
    try {
      const values = await editProfileForm.validateFields();
      setLoading(true);
      const updatedData = {
        ...profileData,
        // firstname: values.firstname,
        // lastname: values.lastname,
        email: values.email,
        username: values.username,
        // default_cs_role_id: values.default_cs_role_id,
        // default_cs_role_iden:
        default_cs_bunit_id: values.default_cs_bunit_id,
      };

      const upsertData =  await upsertProfiledata(JSON.stringify(updatedData));
      console.log(upsertData,"-------->values")
      // Call the upsertProfile mutation with updated data
      // await upsertProfile({ userData: JSON.stringify(updatedData) });
      message.success('Profile updated successfully');
      setIsEditing(false);
      setProfileData(updatedData);
    } catch (error) {
      console.error("Profile update failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginLeft: "1em" }}>
      <Row>
        <Col span={12}>
          <h2 style={{ fontWeight: "700", fontSize: "24px", color: "#161537", marginBottom: "0px", marginTop: "1%" }}>Profile</h2>
        </Col>
      </Row>
      <Card style={{ marginBottom: "8px", borderRadius: "8px", fontFamily: "Inter" }}>
        <Row>
          <Col span={8}>
            <div style={{ position: "relative", display: "inline-block" }}>
              <img src={profileUrl ? profileUrl : ProfilePic} style={{ height: "200px", width: "200px" }} alt="profile" />
            </div>
          </Col>
          <Col span={16} style={{ paddingLeft: "16px" }}>
            <Row>
              <Col span={24}>
                <span style={{ float: "right" }}>
                  <Button
                    style={{
                      background: "#0c173a",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 600
                    }}
                    onClick={getUpdateModal}
                  >
                    <img src={LockIcon} alt="lock" style={{ width: "12px", height: "15px", marginBottom: "2px" }} /> &nbsp;&nbsp;Update Password
                  </Button>
                </span>
              </Col>
            </Row>

            {isEditing ? (
              <Form form={editProfileForm} layout="vertical" initialValues={{
                name: profileData?.name,
                default_cs_role_id: profileData?.default_cs_role_id,
                default_cs_bunit_id: profileData?.default_cs_bunit_id,
                email: profileData?.email,
                username:profileData?.username
              }}>
                <Row gutter={8}>
                  <Col span={12} style={{ marginBottom: "1em" }}>
                    <Form.Item 
                      name="name" 
                      label="Name" 
                      rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ marginBottom: "1em" }}>
                    <Form.Item 
                      name="username" 
                      label="Username" 
                      // rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ marginBottom: "1em" }}>
                    <Form.Item 
                      name="default_cs_role_id" 
                      label="Role"
                    >
                      <Select onChange={handleRoleChange}>
                        {rolesData.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="default_cs_bunit_id" 
                      label="Business Unit"
                    >
                      <Select>
                        {businessUnits.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="email" 
                      label="Email" 
                      rules={[{ required: true, message: 'Please enter your email' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>              
            ) : (
              <>
                <Row gutter={8}>
                  <Col span={6}>
                    <span style={{ verticalAlign: "text-bottom", paddingLeft: 1, fontFamily: "Inter", opacity: 0.6, color: "#000000", fontWeight: 500 }}>
                      Name
                    </span>
                    <h6 style={{ color: "#4E4E4E", fontSize: "12px", marginBottom: "0px" }}>{profileData?.name}</h6>
                  </Col>
                  <Col span={10}>
                    <span style={{ verticalAlign: "text-bottom", paddingLeft: 1, fontFamily: "Inter", opacity: 0.6, color: "#000000", fontWeight: 500 }}>
                      Role
                    </span>
                    <h6 style={{ color: "#4E4E4E", fontSize: "12px", marginBottom: "0px" }}>{role ? role.name : "Loading..."}</h6>
                  </Col>
                </Row>
                <p />
                <Row>
                  <Col span={6}>
                    <span style={{ verticalAlign: "text-bottom", paddingLeft: 1, fontFamily: "Inter", opacity: 0.6, color: "#000000", fontWeight: 500 }}>
                      Business Unit
                    </span>
                    <h6 style={{ color: "#4E4E4E", fontSize: "12px", marginBottom: "0px" }}>{defaultBusinessUnit ? defaultBusinessUnit.name : "Loading..."}</h6>
                  </Col>
                  <Col span={10}>
                    <span style={{ verticalAlign: "text-bottom", paddingLeft: 1, fontFamily: "Inter", opacity: 0.6, color: "#000000", fontWeight: 500 }}>
                      Email
                    </span>
                    <h4>
                      <img src={Envlope} style={{ height: "17px", width: "17px" }} alt="email" />
                      &nbsp;<span style={{ color: "#4E4E4E" }}>{profileData?.email}</span>
                    </h4>
                  </Col>
                </Row>
              </>
            )}

            <div style={{ height: "4rem" }} />

            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                {isEditing ? (
                  <>
                    <Button onClick={handleCancelEdit} style={{ marginRight: '10px',
                      color: "#0c173a",
                      // border: "none",
                      borderRadius: "5px",
                      fontWeight: 600 }}
                      >Cancel</Button>
                    <Button style={{
                      background: "#0c173a",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 600
                    }} type="primary" onClick={handleSaveEdit}>Save</Button>
                  </>
                ) : (
                  <Button style={{
                    background: "#0c173a",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontWeight: 600
                  }} type="primary" onClick={handleEditClick}>Edit</Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {/* Update Password Modal */}
      <Modal
        title="Update Password"
        visible={openModal}
        onCancel={closeModal}
        onOk={updatePassword}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={passWordChangeForm} layout="vertical">
          <Form.Item
            name="old_password"
            label="Old Password"
            rules={[{ required: true, message: "Please input your old password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[{ required: true, message: "Please input your new password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm New Password"
            dependencies={['new_password']}
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
        {loading && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        )}
      </Modal>
    </div>
  );
};

export default Profile;
