import React, { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import ThemeJson from "../../../constants/UIServer.json"
import { getTabColumns, getFilterData } from "../../window/windowUtilities";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { storeReducer } from "../../../lib/storage";
import "./index.css"

const SessionLogs = () => {
  const [finalFilterArray, setFinalFilterArray] = useState([]);
  const [dataSourceRecords, setDataSourceRecords] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [windowTitle, setWindowTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const { windowId } = useParams();
  const serverUrl = process.env.REACT_APP_genericUrl;
  const [windowStore, setWindowStore] = useReducer(storeReducer, { windowDefinition: {} });
  const { access_token } = getOAuthHeaders();
  const Themes = ThemeJson;
  const customInstance = axios.create();

  customInstance.defaults.baseURL = serverUrl;
  customInstance.defaults.method = "POST";
  customInstance.defaults.headers.post["Content-Type"] = "application/json";
  customInstance.defaults.headers.Authorization = `Bearer ${access_token}`;

  customInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        // generateToken();
      } else {
        return Promise.reject(error);
      }
    }
  );

  const processDataWithKeys = (sessionTabData) => {
    const dataArray = sessionTabData
    if (!Array.isArray(dataArray)) {
      console.error("Data array is not an array:", dataArray);
      return [];
    }
    return dataArray.map((item, index) => ({ ...item, key: `${index}-${item.recordId}` }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const createOrderData = await customInstance({
          data: {
            query: `query {
                      sessionWindowDefination
                  }`,
          },
        });

        const windowDefinitionResponse = JSON.parse(createOrderData.data.data.sessionWindowDefination);
        const headerTab = windowDefinitionResponse.tabs.find(tab => tab.tablevel === "0");
        setWindowTitle(windowDefinitionResponse.name);
        headerTab.fields.sort((a, b) => (a.grid_seqno || Infinity) - (b.grid_seqno || Infinity));
        const tabColumnsData = getTabColumns(headerTab);

        const sessionTabData = await customInstance({
          data: {
            query: `query {
                      sessionTabData
                  }`,
          },
        });

        // console.log("Raw sessionTabData response:", sessionTabData.data);

        const rawSessionData = sessionTabData.data.data.sessionTabData;
        // console.log("Parsed sessionTabData:", rawSessionData);

        const filteredData = getFilterData(tabColumnsData, rawSessionData, headerTab);

        const newFinalFilterArray = filteredData.reduce((acc, curr) => {
          if (curr.filteredValue !== null && curr.filteredValue !== undefined) {
            const filterTerm = curr.baseReferenceId === "17" || curr.baseReferenceId === "19" || curr.baseReferenceId === "15" || curr.baseReferenceId === "16"
              ? `[${curr.filteredValue}]`
              : curr.filteredValue;

            acc.push(`{'filterBy':'${curr.dataIndex.replace("_iden", "")}','filterTerm':'${filterTerm}'}`);
          }
          return acc;
        }, []);

        setFinalFilterArray(newFinalFilterArray);

        if (newFinalFilterArray.length > 0) {
          const newFilteredData = getFilterData(tabColumnsData, rawSessionData, headerTab);
          const additionalFilters = newFilteredData.reduce((acc, curr) => {
            if (curr.filteredValue !== null && curr.filteredValue !== undefined) {
              const filterTerm = curr.baseReferenceId === "17" || curr.baseReferenceId === "19" || curr.baseReferenceId === "15" || curr.baseReferenceId === "16"
                ? `[${curr.filteredValue}]`
                : curr.filteredValue;

              acc.push(`{'filterBy':'${curr.dataIndex.replace("_iden", "")}','filterTerm':'${filterTerm}'}`);
            }
            return acc;
          }, []);

          setColumnsData(newFilteredData.map(col => ({ ...col, title: <span className="dragHandler">{col.title}</span> })));
          setFinalFilterArray(prev => [...prev, ...additionalFilters]);
        } else {
          setColumnsData(filteredData.map(col => ({ ...col, title: <span className="dragHandler">{col.title}</span> })));
        }

        const processedData = processDataWithKeys(JSON.parse(sessionTabData.data.data.sessionTabData));
        console.log("Processed Data with Keys:", processedData);
        setDataSourceRecords(processedData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false)
    };

    fetchData();
  }, [windowId]);

  const handleResize = (index) => (e, { size }) => {
    setColumnsData((prevColumns) => {
      const nextColumns = [...prevColumns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return nextColumns;
    });
  };

  const columns = columnsData.map((col, index) => ({
    ...col,
    onHeaderCell: (columns) => ({
      width: columns.width,
      onResize: handleResize(index),
    }),
  }));

  const [rowSelectionKeys, setRowSelectionKeys] = useState([]);
  const onSelectChange = (keys, selectedRows) => {
    setRowSelectionKeys([...keys]);
    // setSelectedRowKeys([...selectedRows]);
  };

  const rowSelection = {
    selectedRowKeys: rowSelectionKeys,
    onChange: onSelectChange,
    hideSelectAll: false,
    fixed: true,
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <span style={{fontFamily:"Inter",fontSize:"24px",fontWeight:600}}>{windowTitle}</span>
        </Col>
      </Row>
      <Table
        style={{ ...Themes.contentWindow.listWindowTable,maxHeight:"10vh" }}
        size="small"
        className="custom-table"
        pagination={false}
        dataSource={dataSourceRecords}
        scroll={{ y: "70vh", x: "100%" }}
        sticky={true}
        columns={columns}
        rowSelection={rowSelection}
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
        }}
      />
    </div>
  );
};

export default SessionLogs;
