import React,{ useState,useEffect } from "react";
import { Col, Row, Dropdown,Menu,Modal,Form,DatePicker,Select, Button,Card,Input,Checkbox } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../lib/storage";
import {getComboFillForDashboard } from "../../services/generic";
import More from "../../assets/images/filtersDash.svg";
import Scrollbars from "react-custom-scrollbars";
import refreshIcon from "../../assets/images/refreshNew.svg";
import moment from 'moment'
// import Axios from "axios";
import "./index.css";


const{Option}=Select;
const { RangePicker } = DatePicker;

const HeaderComponent = (props) => {
  const { globalStore } = useGlobalContext();
  const userPreferences = globalStore.userPreferences;
  const userData = globalStore.userData
  const dateFormat = userPreferences.dateFormat
  const userCurrency = userData.currency
  const {dashboardFilters,isComparableFlag,kpiData,dashboardId,previousYearArray,currentYearDateChange,previousYearDateChange,clearFilterValues,executeDashboard,setLoading,initializationData}=props
  const [filterPopup, setFilterPopup] = useState(false);
  const [popupHeight, setPopupHeight] = useState(false); 
  const [searchValue,setSearchValue]=useState('')
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [dropdownDetailsCopy, setDropdownDetailsCopy] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [businessUnitName,setSelectedBusinessUnitName] = useState([]);
  const [selectedDate,setSelectedDate] = useState();
  const [defaultFromDate,setDefaultFromDate] = useState(null);
  const [defaultToDate,setDefaultToDate] = useState(null)
  const [defaultCompFromDate,setDefaultCompFromDate] = useState(null);
  const [defaultCompToDate,setDefaultCompToDate] = useState(null)
  const [referenceListDetails, setListReferenceDetails] = useState([]);
  const [clickedFiltersCount, setClickedFiltersCount] = useState(0)
  const [mobMenu,setMobMenu] = useState(false)
  const [dateDropDown,setDateDropDown] = useState(false)
  const [bunitVisible,setBunitVisible] = useState(false)
  const [form] = Form.useForm();
  const [comparableForm] = Form.useForm();
  const [dateFilterForm] = Form.useForm();
  const innerWidth = windowSize.innerWidth
  
  const dateConvertor = (str)=>{
    const date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  useEffect(()=>{
    form.resetFields();
    setSelectedBusinessUnitName(initializationData[1]?.defaultValue.name !== undefined ?[initializationData[1]?.defaultValue.name]:[])
    const defaultValue = initializationData[0]?.defaultValue;
    if (defaultValue) {
      const dateArray = defaultValue.split(';');
      const formattedDateRange = dateArray.map(date => moment(date).format(dateFormat)) // Format each date
      .join(' to ');
      setSelectedDate(formattedDateRange);
    } else {
      console.error("defaultValue is not defined");
    }    
    onDropDownSelect(dashboardId,initializationData[1]?.filter_id)
    for (let index = 0; index < initializationData.length; index += 1) {
      const element = initializationData[index]
      if (typeof element.defaultValue === 'string') {
        // const columnName = element.column_name
        // const defaultValue = element.default_value_expression.split(';')
        const [startDate, endDate] = element.defaultValue.split(';');
        const defaultValue = [startDate, endDate]
        const fromDate = new Date(defaultValue[0])
        const toDate = new Date(defaultValue[1])
        const formattedFromDate = new Date(fromDate.getFullYear() - 1 + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate());
          const formattedToDate = new Date(toDate.getFullYear() - 1 + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate());
        setDefaultFromDate(fromDate)
        setDefaultToDate(toDate)
        console.log(fromDate,toDate)
        console.log(formattedFromDate,formattedToDate)
        // if (isComparableFlag === 'Y') {
            setDefaultCompFromDate(formattedFromDate)
            setDefaultCompToDate(formattedToDate)
        // }
      }
     
    }
  },[dashboardId,initializationData])

  const filterMode = () => {
    setClickedFiltersCount(clickedFiltersCount + 1)
    const currentDateArray = []
    // if(clickedFiltersCount===0){
    //   console.log("000")
      for (let index = 0; index < initializationData.length; index += 1) {
        const element = initializationData[index]
        if (typeof element.defaultValue === 'string') {
          // const columnName = element.column_name
          // const defaultValue = element.default_value_expression.split(';')
          const [startDate, endDate] = element.defaultValue.split(';');
          const defaultValue = [startDate, endDate]
          const fromDate = new Date(defaultValue[0])
          const toDate = new Date(defaultValue[1])
          const formattedFromDate = fromDate.getFullYear() - 1 + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
          const formattedToDate = toDate.getFullYear() - 1 + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
          // console.log(defaultFromDate,defaultToDate)
          // console.log(defaultCompFromDate,defaultCompToDate)
          currentDateArray.push(formattedFromDate, formattedToDate)
          form.setFieldsValue({
            fromDate: moment(defaultFromDate, dateFormat),
            toDate: moment(defaultToDate, dateFormat),

          })
  
          if (isComparableFlag === 'Y') {
            comparableForm.setFieldsValue({
              comparableFromDate: moment(defaultCompFromDate, dateFormat),
              comparableToDate: moment(defaultCompToDate, dateFormat),
            })
          }
        }else if (typeof element.defaultValue === 'object') {
         setDropdownDetails([element.defaultValue])
        }
        
      }
    // }
    setFilterPopup(true)
  };

  const submitFilterDetails=()=>{
    form.submit();
  }

  const onFinishFailed = (errorInfo) => {
  };

  const onDropDownSelect = async (dashboard,id) => {
    const getDashboardData = await getComboFillForDashboard(dashboard,id);
    if (getDashboardData && typeof getDashboardData[Symbol.iterator] === 'function') {
      setDropdownDetails([...getDashboardData]);
      setDropdownDetailsCopy([...getDashboardData])
    } else {
      console.error("Error getting dropdown details")
      // Handle non-iterable data (e.g., setDropdownDetails with a default value or handle error)
    }
  };


  const onDropDownSelectForListReference = (values) => {
    setListReferenceDetails([...values]);
  };



  const refreshDashboard = () => {
    props.refreshDashboard()
  };


  const onCurrentFromDateChange = (date, dateString) => {
    const fromDateValue = new Date(date)
    setDefaultFromDate(fromDateValue)
    const fromDateYear = fromDateValue.getFullYear()
    const formattedFromDateToSet = fromDateValue.getFullYear() - 1 + '-' + (fromDateValue.getMonth() + 1) + '-' + fromDateValue.getDate()
    setDefaultCompFromDate(new Date(formattedFromDateToSet))
    if (isComparableFlag === 'Y') {
      comparableForm.setFieldsValue({
        comparableFromDate: moment(new Date(formattedFromDateToSet), dateFormat)
      })
    }
    currentYearDateChange({
      isDateChanged: true,
      currentYearFlag: true,
      fromDateYearInState: fromDateYear,
    })
  }

  const onCurrentToDateChange = (date, dateString) => {
    const toDateValue = new Date(date)
    setDefaultToDate(toDateValue)
    const toDateYear = toDateValue.getFullYear()
    const formattedToDateToSet = toDateValue.getFullYear() - 1 + '-' + (toDateValue.getMonth() + 1) + '-' + toDateValue.getDate()
    setDefaultCompToDate(new Date(formattedToDateToSet))
    if (isComparableFlag === 'Y') {
      comparableForm.setFieldsValue({
        comparableToDate: moment(new Date(formattedToDateToSet), dateFormat),
      })
    }
    currentYearDateChange({
      isDateChanged: true,
      currentYearFlag: true,
      fromDateYearInState: toDateYear,
    })
  }

  const onComparableFromDateChange = (date, dateString) => {  
    const fromDateValue = new Date(dateString)
    const fromDateYear = fromDateValue.getFullYear()
    previousYearDateChange({
      isDateChanged: true,
      previousYearFlag: true,
      fromDateYearInState: fromDateYear,
    })
  }

  const clearValues=()=>{
    setFilterPopup(false)
    setPopupHeight(false)
  }

  const closeFilterModal = () => {
    clearFilterValues()
    setClickedFiltersCount(0)
    // setFilterPopup(false)
    // setPopupHeight(false)
    form.resetFields();
    comparableForm.resetFields();
    dateFilterForm.resetFields();
  };



  const selectTimeLineFilters = (value) => {
    setPopupHeight(false);
    let formattedFromDate, formattedToDate;
  
    switch (value) {
      case "today":
        const todayDate = new Date();
        formattedFromDate = moment(todayDate).format(dateFormat);
        formattedToDate = moment(todayDate).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(todayDate, dateFormat), "toDate": moment(todayDate, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(todayDate, dateFormat), "comparableToDate": moment(todayDate, dateFormat) });
        break;
  
      case "yesterday":
        const yesterdaysDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        formattedFromDate = moment(yesterdaysDate).format(dateFormat);
        formattedToDate = moment(yesterdaysDate).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(yesterdaysDate, dateFormat), "toDate": moment(yesterdaysDate, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "lastSevenDays":
        const currDate = new Date();
        const lastSevenDay = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
        formattedFromDate = moment(lastSevenDay).format(dateFormat);
        formattedToDate = moment(currDate).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(lastSevenDay, dateFormat), "toDate": moment(currDate, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "lastThirtyDays":
        const currDateForThirtyDays = new Date();
        const last30Day = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
        formattedFromDate = moment(last30Day).format(dateFormat);
        formattedToDate = moment(currDateForThirtyDays).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(last30Day, dateFormat), "toDate": moment(currDateForThirtyDays, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "lastNinetyDays":
        const startDateForLastNinetyDays = moment().subtract(90, 'days').toDate();
        const endDateForLastNinetyDays = new Date();
        formattedFromDate = moment(startDateForLastNinetyDays).format(dateFormat);
        formattedToDate = moment(endDateForLastNinetyDays).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(startDateForLastNinetyDays, dateFormat), "toDate": moment(endDateForLastNinetyDays, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "lastMonth":
        const lastMonthDate = new Date();
        const lastMonthFirstDay = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth() - 1, 1);
        const lastMonthLastDay = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth(), 0);
        formattedFromDate = moment(lastMonthFirstDay).format(dateFormat);
        formattedToDate = moment(lastMonthLastDay).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(lastMonthFirstDay, dateFormat), "toDate": moment(lastMonthLastDay, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "lastYear":
        if (userCurrency === "INR") {
          const fiscalYearFirstDay = new Date(new Date().getFullYear() - 1, 3, 1);
          const fiscalYearLastDay = new Date(new Date().getFullYear(), 2, 31);
          formattedFromDate = moment(fiscalYearFirstDay).format(dateFormat);
          formattedToDate = moment(fiscalYearLastDay).format(dateFormat);
          form.setFieldsValue({ "fromDate": moment(fiscalYearFirstDay, dateFormat), "toDate": moment(fiscalYearLastDay, dateFormat) });
          comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        } else {
          const lastYearDate = new Date(new Date().getFullYear() - 1, 0, 1);
          const lastYearFirstDay = new Date(lastYearDate.getFullYear(), 0, 1);
          const lastYearLastDay = new Date(lastYearDate.getFullYear(), 11, 31);
          formattedFromDate = moment(lastYearFirstDay).format(dateFormat);
          formattedToDate = moment(lastYearLastDay).format(dateFormat);
          form.setFieldsValue({ "fromDate": moment(lastYearFirstDay, dateFormat), "toDate": moment(lastYearLastDay, dateFormat) });
          comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        }
        break;
  
      case "thisWeek":
        const current = new Date();
        const firstDayOfWeek = current.getDate() - current.getDay() + 1;
        const lastDayOfWeek = current.getDate();
        const weekStart = new Date(current.setDate(firstDayOfWeek));
        const weekEnd = new Date(current.setDate(lastDayOfWeek));
        formattedFromDate = moment(weekStart).format(dateFormat);
        formattedToDate = moment(weekEnd).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(weekStart, dateFormat), "toDate": moment(weekEnd, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "thisMonth":
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date();
        formattedFromDate = moment(firstDay).format(dateFormat);
        formattedToDate = moment(lastDay).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(firstDay, dateFormat), "toDate": moment(lastDay, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "thisQuarter":
        const startDateOfQuarter = moment().startOf('quarter').toDate();
        const endDateOfQuarter = new Date();
        formattedFromDate = moment(startDateOfQuarter).format(dateFormat);
        formattedToDate = moment(endDateOfQuarter).format(dateFormat);
        form.setFieldsValue({ "fromDate": moment(startDateOfQuarter, dateFormat), "toDate": moment(endDateOfQuarter, dateFormat) });
        comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        break;
  
      case "thisYear":
        if (userCurrency === "INR") {
          const fiscalYearFirstDayForYearToDate = new Date(new Date().getFullYear(), 3, 1);
          formattedFromDate = moment(fiscalYearFirstDayForYearToDate).format(dateFormat);
          formattedToDate = moment(new Date()).format(dateFormat);
          form.setFieldsValue({ "fromDate": moment(fiscalYearFirstDayForYearToDate, dateFormat), "toDate": moment(new Date(), dateFormat) });
          comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        } else {
          const theFirst = new Date(new Date().getFullYear(), 0, 1);
          formattedFromDate = moment(theFirst).format(dateFormat);
          formattedToDate = moment(new Date()).format(dateFormat);
          form.setFieldsValue({ "fromDate": moment(theFirst, dateFormat), "toDate": moment(new Date(), dateFormat) });
          comparableForm.setFieldsValue({ "comparableFromDate": moment(formattedFromDate, dateFormat), "comparableToDate": moment(formattedToDate, dateFormat) });
        }
        break;
  
      default:
        break;
    }
  
    // Set selectedDate as an array with formattedFromDate and formattedToDate
    setSelectedDate(`${formattedFromDate} to ${formattedToDate}`);
  };
  
  
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

 

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 8,
    lg: 12,
    xs: 0,
    sm: 0,
    md: 12,
  };

  const responsiveDate = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 24,
    sm: 24,
    md: 0,
  };
  const responsiveDesignForButtons = {
    xxl: 12,
    xl: 16,
    lg: 12,
    xs: 0,
    sm: 0,
    md: 12,
  };

  const responsiveDesignTitle = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 11,
    sm: 11,
    md: 0,
  };

  const responsiveSpace = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 2,
    sm: 2,
    md: 0,
  };

  const sty = {
    description: "status bar keys styles in the status bar part",
    fontSize: "12px",
    fontFamily:"Inter",
    overflowX: "hidden",
    position: "relative",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight:"7px",
    paddingTop:"2px"
  }

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const onFinish = async (values) => {
    setLoading(true)
    setFilterPopup(false);
    const comparableFormValues = comparableForm.getFieldsValue(true);
    const currentYearFromDate = values.fromDate !== undefined ? values.fromDate.format("YYYY-MM-DD") : "";
    const currentYearToDate = values.toDate !== undefined ? values.toDate.format("YYYY-MM-DD") : "";
    const comparableFromDate = comparableFormValues.comparableFromDate !== undefined ? comparableFormValues.comparableFromDate.format("YYYY-MM-DD") : "";
    const comparableToDate = comparableFormValues.comparableToDate !== undefined ? comparableFormValues.comparableToDate.format("YYYY-MM-DD") : "";
    const currentYearDatesArray = [];
    const previousYearArray = [];
    let comparableValue;
    const formattedFromDate = moment(currentYearFromDate).format(dateFormat);
    const formattedToDate = moment(currentYearToDate).format(dateFormat);
    
    setSelectedDate(`${formattedFromDate} to ${formattedToDate}`);
    // console.log(currentYearFromDate,currentYearToDate)
    currentYearDatesArray.push(currentYearFromDate, currentYearToDate);  
    if (dashboardFilters !== undefined) {
      for (let index = 0; index < dashboardFilters.length; index += 1) {
        const element = dashboardFilters[index];
        if (dashboardFilters[index].type === "DateRange") {
          comparableValue = element.column_name;
          values[element.column_name] = currentYearDatesArray;
        }
      }
      if (isComparableFlag === "Y") {
        const fromDate = new Date(comparableFromDate);
        const toDate = new Date(comparableToDate);
        const formattedFromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
        const formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
        previousYearArray.push(formattedFromDate, formattedToDate);
        values[comparableValue.concat("_COMPARABLE_")] = previousYearArray;
      }
  
      for (const [key, value] of Object.entries(values)) {
        if (value === undefined) {
          delete values[key];
        }
        delete values["fromDate"];
        delete values["toDate"];
      }
      const selectedBusinessUnitNames = [];
      dropdownDetails.forEach((res) => {
        if (values?.cs_bunit_id) {
          values.cs_bunit_id.forEach((tre) => {
            if (res.recordid === tre) {
              selectedBusinessUnitNames.push(res.name);
            }
          });
        }
      });
      // console.log(selectedBusinessUnitNames)
      setSelectedBusinessUnitName(selectedBusinessUnitNames);
      // setSelectedBusinessUnitName(values.cs_bunit_id)
      props.paramsValue(values);
      const stringifiedJSON = JSON.stringify(values);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      props.setFilterJson(jsonToSend)
      const kpiDataCopy = [...kpiData];
    
      setLoading(false)
      props.setFilterFlag(true)
      // console.log(jsonToSend,"-----jsonToSend")
      
    }
  };

  const handleCheckboxChange = async(name,id)=>{
    setLoading(true);
    // Check if the name is already in the list
    const isSelected = businessUnitName.includes(name);
    // Create a new array with updated selection
    const updatedBusinessUnitNames = isSelected
      ? businessUnitName.filter((unitName) => unitName !== name) // Remove the name if it's already selected
      : [...businessUnitName, name]; // Add the name if it's not selected
      // console.log(updatedBusinessUnitNames,"------>names")
    setSelectedBusinessUnitName(updatedBusinessUnitNames);
    setBunitVisible(false);
      const formValues = form.getFieldsValue(true);
      // console.log(selectedDate)
      const currentYearDatesArray = [];
      const currentYearFromDate = formValues.fromDate !== undefined ? formValues.fromDate.format("YYYY-MM-DD") : "";
      const currentYearToDate = formValues.toDate !== undefined ? formValues.toDate.format("YYYY-MM-DD") : "";
      currentYearDatesArray.push(currentYearFromDate, currentYearToDate); 
      let comparableValue;
      if (dashboardFilters !== undefined) {
        for (let index = 0; index < dashboardFilters.length; index += 1) {
          const element = dashboardFilters[index];
          if (dashboardFilters[index].type === "DateRange") {
            comparableValue = element.column_name;
            formValues[element.column_name] = currentYearDatesArray;
          }
        }
        if (isComparableFlag === "Y") {
          const selectedBusinessUnits = dropdownDetails.filter((unit) =>
            updatedBusinessUnitNames.some(
              (name) => name.toLowerCase() === unit.name.toLowerCase()
            )          
        );
        // console.log(selectedBusinessUnits,"-----ids")
        const selectedBusinessUnitIDs = selectedBusinessUnits.map((unit) => unit.recordid);
        // console.log(selectedBusinessUnitIDs,"-----ids")
          // const fromDate = new Date(comparableFromDate);
          // const toDate = new Date(comparableToDate);
          // const formattedFromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
          // const formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
          // previousYearArray.push(formattedFromDate, formattedToDate);
          formValues[comparableValue.concat("_COMPARABLE_")] = previousYearArray;
          formValues["cs_bunit_id"] = selectedBusinessUnitIDs
        }
        // console.log(formValues,"-------values")
        props.paramsValue(formValues);
        props.setFilterFlag(true);
        const stringifiedJSON = JSON.stringify(formValues);
        const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
        props.setFilterJson(jsonToSend)
        const kpiDataCopy = [...kpiData]; // Create a copy to avoid modifying the original array
        // console.log(jsonToSend)
      // Execute dashboards without promises
      // for (const element of kpiDataCopy) {
      //   props.loadingAfterFiltersApplied('Y', element.kpi_id);
      //   await executeDashboard([element], jsonToSend);
      // }
      
      await executeDashboard(kpiData, jsonToSend);
      setLoading(false); 
      }

  }

  const handleChange=(e)=>{
    setSearchValue(e.target.value)
    if(e.target.value !== ""){
      const arr = [];
      dropdownDetails.forEach(tab=>{
       if(tab.name.toLowerCase().indexOf(e.target.value.toLowerCase())>=0){
         arr.push(tab)
       }
      })
     setDropdownDetails(arr)
    }else{
      setDropdownDetails(dropdownDetailsCopy)
    }
    
 }

 const handleSelectAll = (e) => {
  const selectAllChecked = e.target.checked;
  const selectedBusinessUnitNames = selectAllChecked ? dropdownDetails.map(item => item.name) : [];
  setSelectedBusinessUnitName(selectedBusinessUnitNames);
  setLoading(true);
  // Update formValues
  const formValues = dateFilterForm.getFieldsValue(true);
  let comparableValue;
  if (dashboardFilters !== undefined) {
    for (let index = 0; index < dashboardFilters.length; index += 1) {
      const element = dashboardFilters[index];
      if (dashboardFilters[index].type === "DateRange") {
        comparableValue = element.column_name;
        formValues[element.column_name] = selectedDate;
      }
    }
    if (isComparableFlag === "Y") {
      const selectedBusinessUnits = dropdownDetails.filter((unit) =>
        selectedBusinessUnitNames.includes(unit.name)
      );
      const selectedBusinessUnitIDs = selectedBusinessUnits.map((unit) => unit.recordid);
      formValues[comparableValue.concat("_COMPARABLE_")] = previousYearArray;
      formValues["cs_bunit_id"] = selectedBusinessUnitIDs;
    }
  }

  props.paramsValue(formValues);
  const stringifiedJSON = JSON.stringify(formValues);
  const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
  const kpiDataCopy = [...kpiData]; // Create a copy to avoid modifying the original array

  // Execute dashboards without promises
  for (const element of kpiDataCopy) {
    props.loadingAfterFiltersApplied('Y', element.kpi_id);
    executeDashboard([element], jsonToSend);
  }

  setLoading(false);
};

 
  const menu = () => {
    return (
      <Menu
        key="1"
      >
        <Input style={{margin:'7px',width:'225px'}}  value={searchValue} allowClear onChange={handleChange}/>
        <Scrollbars
            style={{
              height: "35vh",
            }}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            thumbSize={90}
            renderView={renderView}
            renderThumbVertical={renderThumb}
          >
          <Card>
          <Menu.Item key="selectAll">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ maxWidth: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Select All</span>
              <Checkbox
                checked={businessUnitName.length === dropdownDetails.length}
                onChange={handleSelectAll}
             />
             </div>   
            </Menu.Item>
            {dropdownDetails?.map((item, index) => {
               const isChecked = businessUnitName?.includes(item.name);
              return (
                <Menu.Item key={index}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{ maxWidth: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.name}</span>
                    <Checkbox key={item.recordid} id={item.recordid} checked={isChecked} onChange={() => handleCheckboxChange(item.name,item.recordid)}/>
                  </div>
                </Menu.Item>
              );
            })}
          </Card>
        </Scrollbars>
      </Menu>
    );
  };

  const dateMenu = () => {
    return(
      <div style={{backgroundColor:"white",padding:"10px"}}>
       <RangePicker onCalendarChange={(val)=>{
        const values = form.getFieldsValue()
       const fromDateValue = new Date(val[0])
       const toDateValue = new Date(val[1])
       setDefaultFromDate(fromDateValue)
       setDefaultToDate(toDateValue)
       setLoading(true)
    setDateDropDown(false)
    const comparableFormValues = comparableForm.getFieldsValue(true);
    const currentYearFromDate = val[0].format("YYYY-MM-DD") ;
    const currentYearToDate =  val[1].format("YYYY-MM-DD") ;
    const comparableFromDate = defaultCompFromDate;
    const comparableToDate = defaultCompToDate;
    const currentYearDatesArray = [];
    const previousYearArray = [];
    let comparableValue;
    const formattedFromDate = moment(fromDateValue).format(dateFormat);
    const formattedToDate = moment(toDateValue).format(dateFormat);
    
    setSelectedDate(`${formattedFromDate} to ${formattedToDate}`);
    // console.log(currentYearFromDate,currentYearToDate)
    currentYearDatesArray.push(currentYearFromDate, currentYearToDate);  
    if (dashboardFilters !== undefined) {
      for (let index = 0; index < dashboardFilters.length; index += 1) {
        const element = dashboardFilters[index];
        if (dashboardFilters[index].type === "DateRange") {
          comparableValue = element.column_name;
          values[element.column_name] = currentYearDatesArray;
        }
      }
      if (isComparableFlag === "Y") {
        const fromDate = new Date(comparableFromDate);
        const toDate = new Date(comparableToDate);
        const formattedFromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
        const formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
        previousYearArray.push(formattedFromDate, formattedToDate);
        values[comparableValue.concat("_COMPARABLE_")] = previousYearArray;
      }
  
      for (const [key, value] of Object.entries(values)) {
        if (value === undefined) {
          delete values[key];
        }
        delete values["fromDate"];
        delete values["toDate"];
      }
      const selectedBusinessUnitNames = [];
      dropdownDetails.forEach((res) => {
        if (values?.cs_bunit_id) {
          values.cs_bunit_id.forEach((tre) => {
            if (res.recordid === tre) {
              selectedBusinessUnitNames.push(res.name);
            }
          });
        }
      });
      // console.log(selectedBusinessUnitNames)
      setSelectedBusinessUnitName(selectedBusinessUnitNames);
      // setSelectedBusinessUnitName(values.cs_bunit_id)
      props.paramsValue(values);
      const stringifiedJSON = JSON.stringify(values);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      props.setFilterJson(jsonToSend)
      const kpiDataCopy = [...kpiData];
    
      setLoading(false)
      props.setFilterFlag(true)
      console.log(jsonToSend,"-----jsonToSend")
      
    }
       }} defaultValue={[moment(new Date(defaultFromDate), dateFormat), moment(new Date(defaultToDate), dateFormat)]} style={{}}/>
      </div>
    )
  }

  return (
    <div>
      {/* {innerWidth>1004? */}
      <Row style={{paddingBottom:"8px"}}>
      <Col {...responsiveDesignTitle}>
        <span className="formRecordTitle" style={{ fontSize: "24px", fontWeight: 600,color: "#192228",lineHeight:'29px',paddingLeft:'4px'}}>{props.dashboardTitle}</span>
        </Col>
        <Col {...responsiveSpace}/>
        <Col {...responsiveDesignTitle} >
        <span style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5vh"}}>
        <div onClick={refreshDashboard} style={{cursor:"pointer"}}>
              <img alt="refresh"  height="1vh" width='1vw'  src={refreshIcon}  style={{ cursor: "pointer",marginBottom:'2%'}} />&nbsp;
              <span style={{fontFamily:"Inter",fontWeight:500,}}>Reload</span>
            </div>
            &emsp;
         
            <div onClick={filterMode} style={{cursor:"pointer"}}>
              <img alt="filter" width="15vw" height="15vh" src={More}  style={{ cursor: "pointer",marginBottom:'2%',paddingRight:"2%"}} />&nbsp; 
              <span style={{fontFamily:"Inter",fontWeight:500}}>More Filters</span>
            </div> 
         </span>
        </Col>
        <Col {...responsiveDate}>
        <span style={{ display:"flex" ,cursor:"pointer",justifyContent:"center",marginTop:"0.4vh"}}>
        {businessUnitName && businessUnitName.length > 0 && (
          <Dropdown overlay={menu} visible={bunitVisible} onVisibleChange={()=>{setBunitVisible(!bunitVisible)}}>
          <span style={sty}>
            <span style={{opacity:0.5, color:"#192228", fontWeight:400, lineHeight:"20px"}}>
              &nbsp;Business Unit
            </span>
            &nbsp;:&nbsp;
            
              <span style={{color:"#192228", fontWeight:"500", fontSize:"12px"}}>
                {businessUnitName[0]}
                {businessUnitName.length > 1 ? ` +${businessUnitName.length - 1}` : ''}
              </span>
          </span>
          </Dropdown>)}
          <span style={sty}>
          {/* <Dropdown overlay={menu} visible={dateDropDown}> */}
            <span style={{opacity:0.5,color:"#192228",fontWeight:400,lineHeight:"20px"}}>&nbsp;Date</span>&nbsp;:&nbsp;<span style={{color:"#192228",fontWeight:"500",fontSize:"12px"}}>{selectedDate}</span>&nbsp;
            {/* </Dropdown> */}
          </span>
          </span>
        </Col>

        <Col {...responsiveDesignForColumn}>
          <span className="formRecordTitle" style={{ fontSize: "24px", fontWeight: "700",color: "#0C173A",lineHeight:'29px',paddingLeft:'4px'}}>{props.dashboardTitle}</span>
        </Col>
        <Col {...responsiveDesignForButtons}>
        <span style={{ float: "right",marginRight:"9px",display:"flex" ,cursor:"pointer"}}>
        {businessUnitName && businessUnitName.length > 0 && (
          <Dropdown overlay={menu} visible={bunitVisible} onVisibleChange={()=>{setBunitVisible(!bunitVisible)}}>
          <span style={sty}>
            <span style={{opacity:0.5, color:"#192228", fontWeight:400, lineHeight:"20px"}}>
              &nbsp;Business Unit
            </span>
            &nbsp;:&nbsp;
            {/* {businessUnitName && businessUnitName.length > 0 ? ( */}
              <span style={{color:"#192228", fontWeight:"500", fontSize:"12px"}}>
                {businessUnitName[0]}
                {businessUnitName.length > 1 ? ` +${businessUnitName.length - 1}` : ''}
              </span>
            {/* ) : (
              <span>No business unit selected</span>
            )} */}
          </span>
          </Dropdown>)}
          <Dropdown  overlay={dateMenu} visible={dateDropDown} onVisibleChange={()=>{setDateDropDown(!dateDropDown)}}>
          <span style={sty}>
            <span style={{opacity:0.5,color:"#192228",fontWeight:400,lineHeight:"20px"}}>&nbsp;Date</span>&nbsp;:&nbsp;<span style={{color:"#192228",fontWeight:"500",fontSize:"12px"}}>{selectedDate}</span>&nbsp;&nbsp;&nbsp;
          </span>
          </Dropdown>
          <div onClick={refreshDashboard} style={{cursor:"pointer"}}>
              <img alt="refresh"   src={refreshIcon}  style={{ cursor: "pointer",marginBottom:'2%'}} />&nbsp;
              <span style={{fontFamily:"Inter",fontSize:"13px",fontWeight:600}}>Reload</span>
            </div>
            &emsp;
         
            <div onClick={filterMode} style={{cursor:"pointer"}}>
              <img alt="filter" width="15vw" height="15vh" src={More}  style={{ cursor: "pointer",marginBottom:'2%',paddingRight:"0"}} />&nbsp; 
              <span style={{fontFamily:"Inter",fontSize:"13px",fontWeight:600}}>Filters</span>
            </div>     
          </span>
        </Col>
      </Row>

      <Modal
        title="Manage Filters"
        visible={filterPopup}
        width="360px"
        className="ant-modal-header-custom"
        // onOk={submitFilterDetails}
        bodyStyle={{paddingTop:"0"}}
        footer={[
          <Button type="default" onClick={closeFilterModal}>
            Clear
          </Button>,
          <Button type="default" onClick={clearValues}>
            Cancel
          </Button>,
           <Button type="primary" onClick={() => {
            setLoading(true)
            setFilterPopup(false);
            setTimeout(() => {
              submitFilterDetails();
            }, 1000); // Delay in milliseconds (adjust as needed)
          }}>
            Apply
          </Button>,
        ]}
        onCancel={clearValues}
        centered
      >
        <div >
          <Form form={dateFilterForm} layout="vertical">
            <Row>
              <Col span={24}>
                <Form.Item                
                  label="Date Range"
                  name="dateRange"
                  // rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                >
                  <Select 
                    allowClear
                    suffixIcon={<CalendarOutlined />}
                    showSearch
                    listHeight={200}
                    placeholder="---Select timeline---"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    // dropdownMatchSelectWidth={false}
                    onSelect={selectTimeLineFilters} 
                    // onFocus={focusTimeLineFilters}
                    // onBlur={unFocusTimeLineFilters}
                    style={{ width: "100%" }}
                    // dropdownStyle={{width:"20%"}}
                    placement="bottomRight"
                  >
                      <Option key="1" value="today">
                        Today
                      </Option>
                      <Option key="2" value="yesterday">
                        Yesterday
                      </Option>
                      {/* <Option key="12" disabled>------------------------------------------------------------</Option> */}
                      <Option key="3" value="lastSevenDays">
                        Last 7 days
                      </Option>
                      <Option key="4" value="lastThirtyDays">
                        Last 30 days
                      </Option>
                      <Option key="6" value="lastMonth">
                        Last month
                      </Option>
                      <Option key="7" value="lastYear">
                        Last year
                      </Option>
                      {/* <Option key="12" disabled>-----------------------------------------------------------</Option> */}
                      <Option key="8" value="thisWeek">
                        Week to date
                      </Option>
                      <Option key="9" value="thisMonth">Month to date</Option>
                      <Option key="10" value="thisQuarter">Quarter to date</Option>
                      <Option key="11" value="thisYear">Year to date</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <br />
          <Row style={{marginTop:popupHeight===true?"50%":"0%"}}>
            {dashboardFilters !== undefined
              ? dashboardFilters.map((dashboardFilters, index) => {
                  return (
                    <Col span={24} key={index} style={{ display: dashboardFilters.is_for_prompting === "Y" ? "block" : "none" }}>
                      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <Form.Item
                          label={dashboardFilters.type === "DateRange" ? null : dashboardFilters.display_name}
                          name={dashboardFilters.type === "DateRange" ? null : dashboardFilters.column_name}
                          rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                        >
                          {dashboardFilters.type === "MultiSelector" ? (
                            <Select
                              style={{ width: "100%" }}
                              mode="multiple"
                              maxTagCount={1}
                              listHeight={100}
                              showSearch
                              allowClear
                              placement="bottomRight"
                              dropdownStyle={{width:"20%"}}
                              dropdownMatchSelectWidth={false}
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onFocus={() => onDropDownSelect(dashboardId,dashboardFilters.filter_id)}
                              defaultValue={dropdownDetails?.[0]?.recordid}
                            >
                              {/* {dropdownList} */}
                              {dropdownDetails === null || dropdownDetails === undefined
                                ? null
                                : dropdownDetails.map((data) => {
                                    return (
                                      <Option key={data.recordid} value={data.recordid}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          ) : dashboardFilters.type === "DateRange" ? (
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="From Date"
                                  name="fromDate"
                                  rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                                >
                                  <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={onCurrentFromDateChange} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="To Date"
                                  name="toDate"
                                  rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                                >
                                  <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={onCurrentToDateChange} />
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : dashboardFilters.type === "List" ? (
                            <Select
                              showSearch
                              allowClear
                              // notFoundContent={fetching ? <Spin size="small" /> : null}
                              dropdownMatchSelectWidth={false}
                              style={{ width: "100%" }}
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onFocus={() => onDropDownSelectForListReference(dashboardFilters.Values)}
                            >
                              {referenceListDetails === null || referenceListDetails === undefined
                                ? null
                                : referenceListDetails.map((data) => {
                                    return (
                                      <Option key={data.key} title={data.key} value={data.key}>
                                        {data.value}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          ) : dashboardFilters.type === "Selector" ? (
                            <Select
                              showSearch
                              allowClear
                              // notFoundContent={fetching ? <Spin size="small" /> : null}
                              dropdownMatchSelectWidth={false}
                              style={{ width: "100%" }}
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onFocus={() => onDropDownSelect(dashboardId,dashboardFilters.id)}
                            >
                              {dropdownDetails === null || dropdownDetails === undefined
                                ? null
                                : dropdownDetails.map((data) => {
                                    return (
                                      <Option key={data.recordid} value={data.recordid}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          ) : dashboardFilters.type === "Date" ? (
                            <DatePicker style={{ width: "100%" }} format={dateFormat} />
                          ) : (
                            ""
                          )}
                        </Form.Item>
                      </Form>
                      <br />
                    </Col>
                  );
                })
              : ""}
          </Row>
          {isComparableFlag === "Y" ? (
            <Form form={comparableForm} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Comparable From Date"
                    name="comparableFromDate"
                    rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                  >
                    <DatePicker style={{ width: "100%" }}  format={dateFormat} onChange={onComparableFromDateChange} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Comparable To Date"
                    name="comparableToDate"
                    rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                  >
                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}

          <br />
        </div>
      </Modal>
    </div>
  );
};

export default HeaderComponent;
